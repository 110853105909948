<template>
  <div class="gift-cards-section">
    <h2>Gift Cards</h2>

    <div class="tutorial-buttons-container">
      <!-- YouTube Button -->
      <button @click="openYouTubeVideo" class="youtube-button">
        <span class="button-icon">▶</span> Watch Video Tutorial
      </button>

      <!-- Interactive Tutorial Button -->
      <button @click="openTutorial" class="tutorial-button">
        <span class="button-icon">i</span> Interactive Tutorial
      </button>
    </div>

    <!-- YouTube Video Popup -->
    <div v-if="showYouTubePopup" class="youtube-popup">
      <div class="youtube-popup-content">
        <button class="close-button" @click="closeYouTubePopup">X</button>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/EAuREUCMZQU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>

    <!-- Interactive Tutorial Popup -->
    <div v-if="showTutorialPopup" class="tutorial-popup">
      <redeem-gift-card-tutorial @close="closeTutorial" />
    </div>
    
    <!-- Search Bar -->
    <div class="search-container">
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Search by business name..." 
        class="search-input"
      />
    </div>
        
    <div class="gift-cards-container">
      <div v-for="(giftCard, cardIndex) in filteredGiftCards" :key="cardIndex" class="gift-card-wrapper">
        <div class="gift-card" :class="{ 'is-flipped': giftCard.isFlipped, 'is-redeemed': giftCard.isRedeemed, 'is-refunded': giftCard.isRefunded }">
          <!-- Front of Card -->
          <div class="gift-card-front" @click="flipCard(giftCard)">
            <div class="gift-card-content">
              <div class="gift-card-header">
                <div class="logo-container">
                  <img src="@/assets/logo-2.png" alt="Starkshoper Logo" class="starkshoper-logo">
                </div>
                <div class="order-info">
                  <div class="order-number">Order number: {{giftCard.orderId}}</div>
                  <div class="order-date">Order date: {{formatDate(giftCard.orderDate)}}</div>
                  <div class="order-total">Order Total: ${{giftCard.orderTotal}}</div>
                </div>
              </div>
              
              <div class="business-info">
                <div class="business-name">{{giftCard.businessName}}</div>
                <div class="gift-card-title">{{giftCard.dealTitle}}</div>
              </div>
              
              <div class="amount-container">
                <div class="amount-label">Amount:</div>
                <div class="amount-value">{{giftCard.originalPrice}}</div>
              </div>
              
              <!-- Status indicator instead of flip arrow for redeemed/refunded -->
              <div v-if="giftCard.isRedeemed" class="status-indicator redeemed">
                <span>Redeemed</span>
              </div>
              <div v-else-if="giftCard.isRefunded" class="status-indicator refunded">
                <span>Refunded</span>
              </div>
              <div v-else class="flip-circle">
                <span class="arrow-icon">→</span>
              </div>
            </div>
          </div>

          <!-- Back of Card (Barcode) -->
          <div class="gift-card-back">
            <div class="barcode-card">
              <div class="barcode-title">{{ giftCard.originalPrice}}</div>
              <div class="barcode-container">
                <svg :id="`barcode-${giftCard.uniqueId}`" class="barcode"></svg>
                <div class="barcode-number">{{formatBarcodeNumber(giftCard.giftCardCode)}}</div>
              </div>
              <div class="button-row">
                <button class="confirm-use-button" @click.stop="showConfirmModal(giftCard)">
                  Confirm Use
                </button>
                <button class="details-button" @click.stop="goToFinePrint(giftCard.giftCardId)">
                  Gift Card Details
                </button>
                <button class="close-button" @click.stop="flipCard(giftCard)">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button v-if="hasMoreFilteredOrders" class="load-more-button" @click="loadMoreOrders">Load More</button>

    <!-- Verification Modal -->
    <div v-if="showConfirmUseModal" class="modal">
      <div class="modal-content">
        <h3>Confirm Use</h3>
        <p>Are you sure you want to use this gift card now? <strong>Once confirmed, the card can't be reused or refunded. Please only use when you are at the location of purchase and verified with an employee.</strong></p>
        <button @click="confirmInStoreUse">Yes, Confirm</button>
        <button @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import RedeemGiftCardTutorial from '@/components/Tutorials/RedeemGiftCardTutorial.vue';

export default {
  name: 'GiftCardsPage',
  components: {
    RedeemGiftCardTutorial
  },
  data() {
    return {
      orders: [],
      paginatedOrders: [],
      itemsPerPage: 10,
      currentPage: 1,
      showConfirmUseModal: false,
      selectedGiftCardCode: '',
      selectedOrderNumber: null,
      selectedDealTitle: null,
      selectedBusinessName: null,
      selectedGiftCardId: null,
      searchQuery: '',
      showYouTubePopup: false,
      showTutorialPopup: false,
      flattenedGiftCards: []
    };
  },
  mounted() {
    this.fetchOrders();
    
    // Check if this is the first visit and show tutorial if needed
    // Add a small delay to ensure the component is fully mounted
    setTimeout(() => {
      if (!this.hasSeenTutorial()) {
        console.log('First visit detected - showing tutorial');
        this.showTutorialPopup = true;
      } else {
        console.log('User has seen the tutorial before');
      }
    }, 500);
  },
  watch: {
    paginatedOrders: {
      handler() {
        this.flattenGiftCards();
        this.$nextTick(() => {
          this.generateAllBarcodes();
        });
      },
      deep: true
    }
  },
  computed: {
    hasMoreOrders() {
      return this.paginatedOrders.length < this.orders.length;
    },
    filteredOrders() {
      if (!this.searchQuery) {
        return this.paginatedOrders;
      }
      
      const query = this.searchQuery.toLowerCase();
      return this.paginatedOrders.filter(order => {
        const businesses = order.items.map(item => item.businessName.toLowerCase());
        return businesses.some(business => business.includes(query));
      });
    },
    filteredGiftCards() {
      if (!this.searchQuery) {
        return this.flattenedGiftCards;
      }
      
      const query = this.searchQuery.toLowerCase();
      return this.flattenedGiftCards.filter(card => 
        card.businessName.toLowerCase().includes(query)
      );
    },
    hasMoreFilteredOrders() {
      if (!this.searchQuery) {
        return this.hasMoreOrders;
      }
      
      const query = this.searchQuery.toLowerCase();
      const filteredCount = this.orders.filter(order => {
        const businesses = order.items.map(item => item.businessName.toLowerCase());
        return businesses.some(business => business.includes(query));
      }).length;
      
      return this.filteredOrders.length < filteredCount;
    }
  },
  methods: {
    async fetchOrders() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/orders/get-orders/${user.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        this.orders = response.data.map(order => {
          if (typeof order.items === 'string') {
            order.items = JSON.parse(order.items);
          }
          return order;
        }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        
        this.updatePaginatedOrders();
      } catch (error) {
        console.error('Error fetching orders:', error);
        alert('Error fetching orders');
      }
    },
    updatePaginatedOrders() {
      const end = this.currentPage * this.itemsPerPage;
      this.paginatedOrders = this.orders.slice(0, end);
    },
    loadMoreOrders() {
      this.currentPage++;
      this.updatePaginatedOrders();
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatBarcodeNumber(code) {
      // Format the barcode number as shown in the example (1234567891234567)
      if (!code) return '1234567891234567';
      // If code is too long, truncate it to 16 characters
      if (code.length > 16) {
        return code.substring(0, 16);
      }
      // If code is too short, pad it with zeros
      if (code.length < 16) {
        return code.padEnd(16, '0');
      }
      return code;
    },
    flattenGiftCards() {
      console.log("Flattening gift cards with fresh data");
      // Clear the array to start fresh
      this.flattenedGiftCards = [];
      
      this.filteredOrders.forEach(order => {
        if (!order.items || !Array.isArray(order.items)) return;
        
        order.items.forEach(item => {
          // Skip if the item doesn't have giftCardCodes
          if (!item.giftCardCodes || !Array.isArray(item.giftCardCodes)) return;
          
          // Check if the item is refunded - this takes precedence
          const isItemRefunded = Boolean(item.refunded);
          
          // Process each gift card code in the item
          item.giftCardCodes.forEach((code, index) => {
            // Determine whether this code is redeemed - only if not refunded
            let isCodeRedeemed = false;
            
            // Only check redemption status if item is not refunded
            if (!isItemRefunded) {
              // Check if the code is an object with a redeemed property
              if (typeof code === 'object' && code !== null) {
                isCodeRedeemed = Boolean(code.redeemed);
              } 
              // Check if the code is an empty string (considered redeemed)
              else if (code === "") {
                isCodeRedeemed = true;
              }
            }
            
            // Get the code value
            const codeValue = (typeof code === 'object' && code !== null) ? code.code : code;
            
            // Create a unique ID that's tied to this specific code
            const uniqueId = `${order.order_id}-${item.giftCardId || 'noId'}-${codeValue || index}`;
            
            // Create the gift card object with a clear redemption status
            this.flattenedGiftCards.push({
              businessName: item.businessName || 'Business',
              dealTitle: item.dealTitle || item.name || 'Gift Card',
              orderId: order.order_id,
              orderDate: order.created_at,
              orderTotal: order.total_cost,
              itemTotal: item.totalCost,
              giftCardCode: codeValue || `code-${index}`,
              giftCardId: item.giftCardId,
              name: item.name || '$0 Gift Card', // Add the name property explicitly
              originalPrice: `$${item.originalPrice} Gift Card`,
              isFlipped: false,
              // If refunded, always set redeemed to false to avoid "redeemed then refunded" state
              isRedeemed: isItemRefunded ? false : isCodeRedeemed,
              isRefunded: isItemRefunded,
              codeIndex: index,
              discount: item.discount,
              uniqueId: uniqueId
            });
          });
        });
      });
      
      // Sort by date (newest first)
      this.flattenedGiftCards.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
      
      console.log('Flattened gift cards count:', this.flattenedGiftCards.length);
    },
    flipCard(giftCard) {
      // Don't allow flipping redeemed or refunded cards
      if (giftCard.isRedeemed || giftCard.isRefunded) {
        return;
      }
      
      giftCard.isFlipped = !giftCard.isFlipped;
      
      if (giftCard.isFlipped) {
        this.$nextTick(() => {
          // Use the uniqueId for barcode generation instead of array index
          this.generateBarcode(giftCard.giftCardCode, `barcode-${giftCard.uniqueId}`);
        });
      }
    },
    generateAllBarcodes() {
      // Use the uniqueId for barcode generation
      this.flattenedGiftCards.forEach((giftCard) => {
        if (giftCard.isFlipped) {
          this.generateBarcode(giftCard.giftCardCode, `barcode-${giftCard.uniqueId}`);
        }
      });
    },
    generateBarcode(code, elementId) {
      const barcodeElement = document.getElementById(elementId);
      
      if (barcodeElement) {
        JsBarcode(barcodeElement, code, {
          format: "CODE128",
          displayValue: false, // No text under barcode as per the example image
          fontSize: 18,
          width: 2,
          height: 100,
          margin: 0
        });
      }
    },
    showConfirmModal(giftCard) {
      this.selectedGiftCardCode = giftCard.giftCardCode;
      this.selectedOrderNumber = giftCard.orderId;
      this.selectedDealTitle = giftCard.dealTitle;
      this.selectedBusinessName = giftCard.businessName;
      this.selectedGiftCardId = giftCard.giftCardId;
      this.showConfirmUseModal = true;
    },
    async confirmInStoreUse() {
      try {
        console.log("Confirming gift card use for code:", this.selectedGiftCardCode);
        
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/orders/redeem-gift-card`, {
          orderNumber: this.selectedOrderNumber,
          dealTitle: this.selectedDealTitle,
          giftCardCode: this.selectedGiftCardCode
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.data.success) {
          alert('Gift card use confirmed in-store!');
          
          // After successful API call, force a complete reload of orders data
          await this.fetchOrders();
          
          // Then rebuild the flattened gift cards to reflect the changes
          this.flattenGiftCards();
        } else {
          alert('Error confirming gift card use');
        }
      } catch (error) {
        console.error('Error confirming gift card use:', error);
        alert('Error confirming gift card use');
      } finally {
        this.closeModal();
      }
    },
    goToFinePrint(giftCardId) {
      this.$router.push(`/gift-card/${giftCardId}`);
    },
    closeModal() {
      this.showConfirmUseModal = false;
    },
    openYouTubeVideo() {
      this.showYouTubePopup = true;
    },
    closeYouTubePopup() {
      this.showYouTubePopup = false;
    },
    openTutorial() {
      this.showTutorialPopup = true;
    },
    closeTutorial() {
      this.showTutorialPopup = false;
      // Mark the tutorial as seen when closed
      this.markTutorialSeen();
    },
    extractAmount(dealTitle) {
      // Extract the dollar amount from a string like "$20 Worth of Pizza, Pasta, Wings, and Subs"
      const match = dealTitle.match(/\$(\d+)/);
      return match ? match[1] : '20'; // Default to 20 if not found
    },
    extractProduct(dealTitle) {
      // Extract the product description from a string like "$20 Worth of Pizza, Pasta, Wings, and Subs"
      const match = dealTitle.match(/Worth of (.*)/i);
      return match ? match[1] : 'Food & Drinks'; // Default if not found
    },
    // Improved method to check if user has seen the tutorial
    hasSeenTutorial() {
      const seen = localStorage.getItem('giftCardTutorialSeen');
      console.log('Tutorial seen status from localStorage:', seen);
      return seen === 'true';
    },
    
    // Improved method to mark tutorial as seen
    markTutorialSeen() {
      console.log('Marking tutorial as seen in localStorage');
      localStorage.setItem('giftCardTutorialSeen', 'true');
      // Verify it was set
      const verifySet = localStorage.getItem('giftCardTutorialSeen');
      console.log('Verified localStorage value:', verifySet);
    }
  }
};
</script>

<style scoped>
.gift-cards-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gift-cards-section h2 {
  margin-bottom: 20px;
  text-align: left;
  font-size: 28px;
  color: #333;
}

/* Search Bar Styles */
.search-container {
  margin-bottom: 30px;
  text-align: left;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  outline: none;
  border-color: #4C6B30;
  box-shadow: 0 2px 8px rgba(76, 107, 48, 0.2);
}

/* YouTube Button Styles */
.youtube-button-container {
  text-align: left;
  margin: 20px 0 30px 0;
}

.youtube-button-container button {
  padding: 15px 25px;
  font-size: 18px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
}

.youtube-button-container button:hover {
  background-color: darkred;
}

/* YouTube Popup Styles */
.youtube-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.youtube-popup-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 560px;
}

.youtube-popup-content iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.youtube-popup-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  padding: 0;
  width: auto;
  border-radius: 0;
}

.youtube-popup-content .close-button:hover {
  background-color: transparent;
  color: #4C6B30;
}

/* Gift Cards Container */
.gift-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

/* Gift Card Styles with Flip Animation */
.gift-card-wrapper {
  perspective: 1000px;
  height: 300px; /* Increased from 280px to 300px for more space */
  max-width: 600px; /* Set card width as shown in the image */
}

.gift-card {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gift-card.is-flipped {
  transform: rotateY(180deg);
}

.gift-card-front, .gift-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
}

/* Front of Card */
.gift-card-front {
  background-color: white;
  display: flex;
  align-items: center;
}

.gift-card-content {
  width: 100%;
  padding: 15px; /* Reduced padding from 20px to 15px */
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between; /* Distribute space evenly */
  overflow-y: visible; /* Change from auto to visible - no scrolling */
}

.gift-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
}

.order-info {
  font-size: 14px;
  color: #666;
  padding: 5px 10px;
  flex: 1;
  text-align: right; /* Align text to the right */
  line-height: 1.0; /* Add more line height */
}

.order-number, .order-date, .order-total {
  margin-bottom: 8px; /* Increased from 5px to add more space between items */
  padding: 2px 0; /* Add a little vertical padding */
}

.logo-container {
  width: 70px; /* Increased from 60px */
  height: 70px; /* Increased from 60px */
  min-width: 70px; /* Ensure it doesn't shrink */
  margin-right: 15px; /* Increased from 10px */
  padding: 5px; /* Add some padding inside */
  display: flex;
  justify-content: center;
  align-items: center;
}

.starkshoper-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
}

.business-info {
  margin-bottom: 8px; /* Reduced from 10px */
  text-align: center;
  align-self: center;
}

.business-name {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.gift-card-title {
  font-size: 18px;
  color: #666;
}

.amount-container {
  display: flex;
  margin-bottom: 8px; /* Reduced from 10px */
  justify-content: center;
  align-self: center;
}

.amount-label {
  font-weight: bold;
  margin-right: 5px;
}

.amount-value {
  color: #4C6B30;
}

.flip-circle {
  position: relative;
  width: 40px; /* Slightly smaller */
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-self: center;
  margin: 8px auto 10px; /* Reduced margins */
  flex-shrink: 0;
  flex-grow: 0;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #333;
}

/* Back of Card (Barcode) */
.gift-card-back {
  background-color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
}

.barcode-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.barcode-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.barcode-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.barcode {
  max-width: 100%;
  height: 80px;
}

.barcode-number {
  font-size: 14px;
  margin-top: 5px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.confirm-use-button {
  flex: 1;
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.details-button {
  flex: 1;
  background-color: #6E9F49;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  flex: 1;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.confirm-use-button:hover {
  background-color: #3a5324;
}

.details-button:hover {
  background-color: #5c8a3c;
}

.close-button:hover {
  background-color: #c82333;
}

.load-more-button {
  display: block;
  margin: 0 auto;
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
}

.load-more-button:hover {
  background-color: #6E9F49;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

.modal-content p {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.5;
}

.modal-content button {
  margin: 0 8px;
  padding: 12px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.modal-content button:first-of-type {
  background-color: #4C6B30;
  color: white;
}

.modal-content button:first-of-type:hover {
  background-color: #6E9F49;
}

.modal-content button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-content button:last-of-type:hover {
  background-color: #c82333;
}

/* Visual indicators for redeemed/refunded cards */
.gift-card.is-redeemed .gift-card-front,
.gift-card.is-refunded .gift-card-front {
  cursor: default;
  opacity: 0.9;
}

.status-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  border-radius: 20px;
  font-weight: bold;
  align-self: center;
  margin: 8px auto 10px;
}

.status-indicator.redeemed {
  background-color: #CCCCCC;
  color: white;
}

.status-indicator.refunded {
  background-color: #FF9800;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .gift-card-wrapper {
    height: 320px; /* Increase height on smaller screens */
  }
  
  .gift-card-header {
    flex-direction: row; /* Keep as row even on mobile */
    padding: 5px;
    gap: 10px;
    align-items: center; /* Center align items vertically */
  }
  
  .logo-container {
    margin-right: 10px; /* Add right margin to keep space between logo and order info */
    align-self: flex-start; /* Align to top */
    width: 60px; /* Slightly smaller on mobile to give more space to order info */
    height: 60px;
    min-width: 60px;
  }
  
  .order-info {
    text-align: right; /* Keep right-aligned */
    padding: 5px;
    font-size: 12px; /* Slightly smaller font on mobile */
  }
  
  .search-input {
    max-width: 100%;
    font-size: 14px;
    padding: 10px;
  }
  
  .youtube-button-container button {
    padding: 12px 15px;
    font-size: 16px;
  }
  
  .button-row {
    flex-direction: column;
  }
  
  .confirm-use-button, .details-button, .close-button {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .gift-card-wrapper {
    height: 360px; /* Further increase height on very small screens */
  }
  
  .gift-card-header {
    flex-direction: row; /* Keep as row even on very small screens */
    align-items: flex-start;
  }
  
  .logo-container {
    width: 50px; /* Even smaller on very small screens */
    height: 50px;
    min-width: 50px;
    margin-right: 8px;
  }
  
  .order-info {
    font-size: 11px; /* Further reduce font size */
  }
  
  .youtube-button-container button {
    padding: 10px 15px;
    font-size: 14px;
    width: 100%;
  }
  
  .modal-content {
    padding: 15px;
    width: 95%;
  }
  
  .modal-content button {
    padding: 10px 15px;
    font-size: 14px;
    margin: 5px 0;
    display: block;
    width: 100%;
  }
}

/* Tutorial Buttons Container */
.tutorial-buttons-container {
  display: flex;
  gap: 15px;
  margin: 20px 0 30px 0;
}

.youtube-button, .tutorial-button {
  padding: 15px 25px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.youtube-button {
  background-color: #e62117;
  color: white;
}

.tutorial-button {
  background-color: #4C6B30;
  color: white;
}

.youtube-button:hover {
  background-color: #c41e0f;
}

.tutorial-button:hover {
  background-color: #3a5324;
}

.button-icon {
  margin-right: 10px;
  font-size: 20px;
}

/* Tutorial Popup */
.tutorial-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Responsive styles for buttons */
@media (max-width: 768px) {
  .tutorial-buttons-container {
    flex-direction: column;
    gap: 10px;
  }
  
  .youtube-button, .tutorial-button {
    width: 100%;
    justify-content: center;
    padding: 12px 15px;
    font-size: 16px;
  }
}
</style>