<template>
  <div class="container">
    <MetaManager
      title="Register"
      description="Register today to unlock exclusive deals and purchase gift cards. Enjoy savings on your favorite brands and enhance your shopping experience today."
    />
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p class="loading-message">Creating your account, please wait...</p>
    </div>
    <div v-else class="form-container">
      <h2 class="title">Sign up and get access to great deals</h2>
      <form @submit.prevent="register">
        <div class="input-group">
          <label for="fullName">Full Name</label>
          <input type="text" id="fullName" v-model="fullName" required />
        </div>
        <div class="input-group">
          <label for="email">E-mail Address</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="input-group">
          <label from="phone">Phone Number</label>
          <input type="tel" id="phone" v-model="phone" required />
        </div>
        <div class="input-group">
          <label for="password">Password</label>
          <div class="password-field">
            <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required />
            <button type="button" class="password-toggle" @click="togglePasswordVisibility">
              <i :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <div class="input-group">
          <label for="confirmPassword">Confirm Password</label>
          <div class="password-field">
            <input :type="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" required />
            <button type="button" class="password-toggle" @click="toggleConfirmPasswordVisibility">
              <i :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <div class="terms-checkbox">
          <input 
            type="checkbox" 
            id="terms" 
            v-model="agreedToTerms" 
            required 
          />
          <label for="terms">
            I have read and agree to the 
            <a href="/terms-of-service" target="_blank">Terms of Service</a> 
            and 
            <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
          </label>
        </div>
        <button type="submit" class="register-button">Register</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <div v-if="successMessage" class="success-message" v-html="successMessage"></div>
      </form>
      <br>
      <div class="or-register">or register with</div>
      <div class="social-login">
        <div id="g_id_onload"
             :data-client_id="googleClientId"
             data-context="signup"
             data-ux_mode="popup"
             data-callback="handleGoogleSignUp"
             data-auto_prompt="false">
        </div>
        <div class="g_id_signin"
             data-type="standard"
             data-shape="rectangular"
             data-theme="outline"
             data-text="signup_with"
             data-size="large"
             data-logo_alignment="left">
        </div>
      </div>
    </div>
    <div class="login-redirect">
      <button @click="redirectToLogin" class="login-button">Have an account? Login</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { setUser } from '../../services/auth';
import { useRecaptcha } from '@/useRecaptcha';
import MetaManager from '../../components/MetaManager.vue';

export default {
  components: {
    MetaManager
  },
  data() {
    return {
      fullName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      errorMessage: '',
      loading: false,
      googleClientId: '162400744570-8mffcm4pl6acib2jh0iqm94tp6l5nko4.apps.googleusercontent.com',
      showPassword: false,
      showConfirmPassword: false,
      agreedToTerms: false
    };
  },
  mounted() {
    this.loadGoogleIdentityServices();
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async register() {
      const { executeRecaptcha } = useRecaptcha();
      try {
        if (this.password.length < 8) {
          this.errorMessage = 'Password must be at least 8 characters long.';
          return;
        }
        if (this.password !== this.confirmPassword) {
          this.errorMessage = 'Passwords do not match.';
          return;
        }
        this.loading = true;
        const recaptchaToken = await executeRecaptcha();
        await axios.post(`${process.env.VUE_APP_API_URL}/api/register`, {
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          password: this.password,
          recaptchaToken,
        });
        this.loading = false;
        // Redirect to the thank you page instead of showing the success message
        this.$router.push('/thank-you-register');
      } catch (error) {
        this.loading = false;
        console.error('Error registering', error);
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = error.response.data.error;
        }
      }
    },
    handleSuccessfulRegistration(data) {
      setUser(data.user);
      localStorage.setItem('token', data.token);
      localStorage.setItem('shoppingBagItems', JSON.stringify(data.user.shoppingBagItems));
      localStorage.setItem('checkoutCartItems', JSON.stringify(data.user.checkoutCartItems));
      localStorage.setItem('billingDetails', JSON.stringify(data.user.billingDetails));
      this.loading = false;
      this.$router.push({ path: `/account/${data.user.id}/orders` });
    },
    clearForm() {
      this.fullName = '';
      this.email = '';
      this.phone = ''
      this.password = '';
      this.confirmPassword = '';
    },
    loadGoogleIdentityServices() {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      window.handleGoogleSignUp = this.handleGoogleSignUp;
    },
    async handleGoogleSignUp(response) {
      if (response.credential) {
        try {
          this.loading = true;
          const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/register/google`, {
            idToken: response.credential,
          });
          setTimeout(() => {
            this.handleSuccessfulRegistration(result.data);
          }, 2000);
        } catch (error) {
          this.loading = false;
          this.errorMessage = error.response.data.error;
        }
      }
    },
    redirectToLogin() {
      localStorage.setItem('redirectAfterLogin', this.$route.fullPath);
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 65vh;
  padding: 1rem;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #4C6B30;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-message {
  color: #4C6B30;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 1rem 0;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-group input:focus {
  border-color: #6E9F49; /* Set your green color here */
  outline: none;
}

.register-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #4C6B30;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.register-button:hover {
  background-color: #6E9F49;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
}

.or-register {
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0;
  color: #666;
}

.success-message a {
  color: #4C6B30;
  text-decoration: none;
}

.success-message a:hover {
  text-decoration: underline;
}

.login-link {
  font-weight: bold;
}

.support-link {
  font-weight: bold;
}

.social-login {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.login-redirect {
  margin-top: 1rem;
  text-align: center;
}

.login-button {
  background: none;
  border: none;
  color: #4C6B30;
  cursor: pointer;
  font-size: 1rem;
}

.success-message {
  color: green;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
}

.login-button:hover {
  text-decoration: underline;
}

.terms-checkbox {
  margin: 20px 0;
  font-size: 12px;
}

.terms-checkbox a {
  color: #4C6B30;
  text-decoration: none;
}

.terms-checkbox a:hover {
  text-decoration: underline;
}

.password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.password-field input {
  width: 100%;
  padding-right: 40px; /* Make space for the eye icon */
}

.password-toggle {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0;
  font-size: 1rem;
}

.password-toggle:hover {
  color: #4C6B30;
}

@media (max-width: 1600px){
  .container{
    padding-top: 120px;
  }
}

@media (max-width: 1024px){
  .container{
    margin-top: 50px;
    padding-top: 150px;
    padding-bottom: 0px;
    max-height: 5vh;
  }
}

@media (max-width: 768px) {
  .form-container {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.3rem;
  }

  .input-group input {
    font-size: 0.9rem;
  }

  .register-button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .login-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0.5rem; /* Adjust padding for very small screens */
    padding-top: 50px;
    padding-bottom: 90px;
    max-height: 50vh;
  }

  .form-container {
    max-width: 320px;
  }

  .title {
    font-size: 1.1rem;
  }

  .input-group input {
    font-size: 0.8rem;
  }

  .register-button {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .login-button {
    font-size: 0.8rem;
  }
}
</style>
