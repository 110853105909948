<template>
    <div class="change-password-section">
      <h2>Change Password</h2>
      <form @submit.prevent="handleChangePassword">
        <div class="form-group">
          <label for="currentPassword">Current Password</label>
          <div class="password-field">
            <input :type="showCurrentPassword ? 'text' : 'password'" id="currentPassword" v-model="currentPassword" required />
            <button type="button" class="password-toggle" @click="toggleCurrentPasswordVisibility">
              <i :class="showCurrentPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <div class="password-field">
            <input :type="showNewPassword ? 'text' : 'password'" id="newPassword" v-model="newPassword" required />
            <button type="button" class="password-toggle" @click="toggleNewPasswordVisibility">
              <i :class="showNewPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <div class="password-field">
            <input :type="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" required />
            <button type="button" class="password-toggle" @click="toggleConfirmPasswordVisibility">
              <i :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <button type="submit" class="change-password-button">Change Password</button>
      </form>
      <div v-if="message" class="message">{{ message }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ChangePasswordPage',
    data() {
      return {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        message: '',
        token: localStorage.getItem('token'),
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
      };
    },
    methods: {
      toggleCurrentPasswordVisibility() {
        this.showCurrentPassword = !this.showCurrentPassword;
      },
      toggleNewPasswordVisibility() {
        this.showNewPassword = !this.showNewPassword;
      },
      toggleConfirmPasswordVisibility() {
        this.showConfirmPassword = !this.showConfirmPassword;
      },
      async handleChangePassword() {
        if (this.newPassword !== this.confirmPassword) {
          this.message = 'New password and confirm password do not match.';
          return;
        }
  
        try {
          const userId = JSON.parse(localStorage.getItem('user')).id;
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/change-password`, {
            userId,
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          },{
            headers: { 'Authorization': `Bearer ${this.token}` }
          });
  
          if (response.data.success) {
            this.message = 'Password changed successfully.';
          } else {
            this.message = 'Error changing password: ' + response.data.message;
          }
        } catch (error) {
          this.message = 'Error changing password: ' + error.message;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .change-password-section {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    max-width: 500px;
  }
  
  .change-password-section h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .form-group input:focus {
    outline: none;
    border-color: #6E9F49;
  }
  
  .change-password-button {
    padding: 10px 20px;
    background-color: #4C6B30;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .change-password-button:hover {
    background-color: #6E9F49;
  }

  .message {
    margin-top: 20px;
    color: #6E9F49;
  }

  .password-field {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-field input {
    width: 100%;
    padding-right: 40px; /* Make space for the eye icon */
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 0;
    font-size: 1rem;
  }
  
  .password-toggle:hover {
    color: #6E9F49;
  }
  </style>
