<template>
  <div class="thank-you-container">
    <MetaManager
      title="Registration Successful"
      description="Thank you for registering with ShopStark. Please check your email to verify your account."
    />
    <div class="thank-you-card">
      <h1 class="title">Thank You for Registering!</h1>
      <div class="message">
        <p>You will receive an email shortly to verify your account.</p>
        <p>Sometimes the emails end up in the Spam or Junk folder so be sure to check those. In rare cases, your email provider gets overloaded and slows the email delivery.</p>
        <p>If you don't receive the email within 15 minutes, please reach out to our support via the button below or our chat widget and we will manually get you verified.</p>
      </div>
      <div class="buttons">
        <button @click="goToLogin" class="primary-button">Go to Login</button>
        <button @click="goToSupport" class="secondary-button">Contact Support</button>
      </div>
    </div>
  </div>
</template>

<script>
import MetaManager from '../../components/MetaManager.vue';

export default {
  components: {
    MetaManager
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    goToSupport() {
      this.$router.push('/support');
    }
  }
};
</script>

<style scoped>
.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
  background-color: #f5f5f5;
}

.thank-you-card {
  background-color: white;
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.title {
  color: #4C6B30;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.message {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.message p {
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.primary-button, .secondary-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button {
  background-color: #4C6B30;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: #6E9F49;
}

.secondary-button {
  background-color: white;
  color: #4C6B30;
  border: 1px solid #4C6B30;
}

.secondary-button:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .thank-you-card {
    padding: 1.5rem;
  }
  
  .buttons {
    flex-direction: column;
  }
  
  .primary-button, .secondary-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
</style>
